import { parseISO, format } from 'date-fns';
import { toZonedTime, format as tzFormat } from 'date-fns-tz';

interface State {
  name: string;
  shortName: string;
}

export const states: State[] = [
  {
    name: 'Washington',
    shortName: 'WA',
  },
  {
    name: 'Virginia',
    shortName: 'VA',
  },
  {
    name: 'Delaware',
    shortName: 'DE',
  },
  {
    name: 'District of Columbia',
    shortName: 'DC',
  },
  {
    name: 'Wisconsin',
    shortName: 'WI',
  },
  {
    name: 'West Virginia',
    shortName: 'WV',
  },
  {
    name: 'Hawaii',
    shortName: 'HI',
  },
  {
    name: 'Florida',
    shortName: 'FL',
  },
  {
    name: 'Wyoming',
    shortName: 'WY',
  },
  {
    name: 'New Hampshire',
    shortName: 'NH',
  },
  {
    name: 'New Jersey',
    shortName: 'NJ',
  },
  {
    name: 'New Mexico',
    shortName: 'NM',
  },
  {
    name: 'Texas',
    shortName: 'TX',
  },
  {
    name: 'Louisiana',
    shortName: 'LA',
  },
  {
    name: 'North Carolina',
    shortName: 'NC',
  },
  {
    name: 'North Dakota',
    shortName: 'ND',
  },
  {
    name: 'Nebraska',
    shortName: 'NE',
  },
  {
    name: 'Tennessee',
    shortName: 'TN',
  },
  {
    name: 'New York',
    shortName: 'NY',
  },
  {
    name: 'Pennsylvania',
    shortName: 'PA',
  },
  {
    name: 'California',
    shortName: 'CA',
  },
  {
    name: 'Nevada',
    shortName: 'NV',
  },
  {
    name: 'Puerto Rico',
    shortName: 'PR',
  },
  {
    name: 'Colorado',
    shortName: 'CO',
  },
  {
    name: 'Virgin Islands',
    shortName: 'VI',
  },
  {
    name: 'Alaska',
    shortName: 'AK',
  },
  {
    name: 'Alabama',
    shortName: 'AL',
  },
  {
    name: 'Arkansas',
    shortName: 'AR',
  },
  {
    name: 'Vermont',
    shortName: 'VT',
  },
  {
    name: 'Illinois',
    shortName: 'IL',
  },
  {
    name: 'Georgia',
    shortName: 'GA',
  },
  {
    name: 'Indiana',
    shortName: 'IN',
  },
  {
    name: 'Iowa',
    shortName: 'IA',
  },
  {
    name: 'Oklahoma',
    shortName: 'OK',
  },
  {
    name: 'Arizona',
    shortName: 'AZ',
  },
  {
    name: 'Idaho',
    shortName: 'ID',
  },
  {
    name: 'Connecticut',
    shortName: 'CT',
  },
  {
    name: 'Maine',
    shortName: 'ME',
  },
  {
    name: 'Maryland',
    shortName: 'MD',
  },
  {
    name: 'Massachusetts',
    shortName: 'MA',
  },
  {
    name: 'Ohio',
    shortName: 'OH',
  },
  {
    name: 'Utah',
    shortName: 'UT',
  },
  {
    name: 'Missouri',
    shortName: 'MO',
  },
  {
    name: 'Minnesota',
    shortName: 'MN',
  },
  {
    name: 'Michigan',
    shortName: 'MI',
  },
  {
    name: 'Rhode Island',
    shortName: 'RI',
  },
  {
    name: 'Kansas',
    shortName: 'KS',
  },
  {
    name: 'Montana',
    shortName: 'MT',
  },
  {
    name: 'Mississippi',
    shortName: 'MS',
  },
  {
    name: 'South Carolina',
    shortName: 'SC',
  },
  {
    name: 'Kentucky',
    shortName: 'KY',
  },
  {
    name: 'Oregon',
    shortName: 'OR',
  },
  {
    name: 'South Dakota',
    shortName: 'SD',
  },
].sort((a, b) => a.name.localeCompare(b.name));

export const formatInDollars = (amountInCents: string | number) => {
  const price = typeof amountInCents === 'string' ? parseFloat(amountInCents) : amountInCents / 100;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
};

export const parseSplitVisitDateString = (date: string) => {
  const [dateString, timeString, timezone] = date.split(' ');

  const isoDate = `${dateString}T${timeString}${timezone}`;

  const jsDate = parseISO(isoDate);

  return jsDate;
};

export const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getTimezoneAbbreviation = () => {
  const now = new Date();
  const zonedDate = toZonedTime(now, clientTimezone);

  return tzFormat(zonedDate, 'zzz', { timeZone: clientTimezone });
};

export const formatSplitVisitTimeToLocal = (timestamp: string) => {
  'use client';
  const date = parseSplitVisitDateString(timestamp);

  const formattedDayDate = format(date, 'iiii, LLLL do');
  const formattedTime = format(date, 'p');
  const timezoneAbbreviation = getTimezoneAbbreviation();

  return `${formattedDayDate} at ${formattedTime} - ${timezoneAbbreviation}`;
};
